angular.module('eOpti.services').provider('breadcrumb', function () {
    let _top = true

    this.setTitle = bool => {
        _top = bool
    }

    this.$get = ['$state', function($state) {
        let functionList = (prefix_state, args) => {
            let result = {
                name: args,
                type: 'list'
            }

            if(args) {
                result.state = prefix_state + '.' + args.substr(args.lastIndexOf('.') + 1).toLowerCase()
                result.href = $state.href(result.state)
            }

            return result
        },
        generateList = (prefix_name, prefix_state, statuses) => {
            let _return = []

            angular.forEach(statuses, status => {
               _return.push({
                   name: prefix_name + '.' + status.toUpperCase(),
                   href: $state.href(prefix_state + '.' + status)
               })
            })

            return _return
        }

        return {
            top: _top,
            lists: {
                tasks: generateList('tasks.status', 'app.tasks', ['temporary', 'notaccepted', 'accepted', 'confirmed',
                    'uncompleted', 'completed', 'abraded', 'checked', 'sent', 'received', 'done', 'cancelled', 'all']),
                returns: generateList('returns.status', 'app.warehouse.returns', ['placed', 'done', 'cancelled', 'all']),
                outcomingtransfers: generateList('transfers.status', 'app.warehouse.transfers.outcoming', [
                    'ready', 'sent', 'received', 'cancelled', 'all']),
                incomingtransfers: generateList('transfers.status', 'app.warehouse.transfers.incoming', [
                    'ready', 'sent', 'received', 'cancelled', 'all']),
                outcomingshipments: generateList('shipments.status', 'app.warehouse.shipments.outcoming', ['ready',
                    'sent', 'received', 'cancelled', 'all']),
                incomingshipments: generateList('shipments.status', 'app.warehouse.shipments.incoming', ['ready',
                    'sent', 'received', 'cancelled', 'all']),
                orders: generateList('orders.status', 'app.warehouse.orders', ['accepted', 'confirmed', 'sent', 'done', 'cancelled', 'all']),
                salereclamations: generateList('sale.reclamation.status', 'app.sale.reclamations', ['accepted', 'acceptedsent',
                    'receivedwarehouse', 'considered', 'beingrepaired', 'sent', 'received', 'done', 'cancelled', 'all']),
                reclamations: generateList('reclamations.status', 'app.tasks.reclamations', ['accepted', 'acceptedsent',
                    'waitingforparts', 'beingrepaired', 'readytobesent', 'sent', 'received', 'done', 'cancelled', 'all']),
                errors: generateList('bugtrack.status', 'app.errors', ['new', 'confirmed', 'duringchange', 'solved', 'checked',
                    'closed', 'froze', 'cancelled', 'all']),
                regenerations: generateList('regeneration.status', 'app.regenerations', ['department', 'sentwarehouse', 'receivewarehouse',
                    'sentspecialist', 'receivespecialist', 'assembly', 'sentdepartment', 'receivedepartment', 'done', 'cancel', 'all']),
                eShopOrders: generateList('eShop.order.status', 'app.eShop.orders', ['pending', 'pending_payment', 'holded', 'pending_ok', 'processing', 'processing_ready_for_shipment',
                    'complete', 'closed', 'canceled', 'all'])
            },
            defaults: {
                patientsList: {
                    type: 'state',
                    state: 'app.patients.list',
                    name: 'patient.breadcrumb.PATIENTS'
                },
                usersList: {
                    type: 'state',
                    state: 'app.users.list',
                    name: 'user.breadcrumb.USERS'
                },
                settingsList: {
                    type: 'state',
                    state: 'app.settings',
                    name: 'settings.breadcrumb.SETTINGS'
                },
                taskExtraList: {
                    type: 'state',
                    state: 'app.dictionary.taskextras.table',
                    name: 'dictionary.taskextra.breadcrumb.EXTRA'
                },
                regenerationTypeList: {
                    type: 'state',
                    state: 'app.dictionary.regenerationtypes.table',
                    name: 'dictionary.regenerationtype.breadcrumb.TYPE'
                },
                specialistList: {
                    type: 'state',
                    state: 'app.dictionary.specialists.table',
                    name: 'dictionary.specialist.breadcrumb.SPECIALISTS'
                },
                doctorList: {
                    type: 'state',
                    state: 'app.dictionary.doctors.table',
                    name: 'dictionary.doctor.breadcrumb.DOCTORS'
                },
                reclamationServiceList: {
                    type: 'state',
                    state: 'app.dictionary.reclamationservices.table',
                    name: 'dictionary.reclamationservice.breadcrumb.RECLAMATIONSERVICES'
                },
                servicesList: {
                    type: 'state',
                    state: 'app.assemblies.list',
                    name: 'services.breadcrumb.SERVICES'
                },
                productsAttributesList: {
                    type: 'state',
                    state: 'app.warehouse.products.attributes.list',
                    name: 'attributes.product.breadcrumb.ATTRIBUTES'
                },
                marketingList: {
                    type: 'state',
                    state: 'app.marketing.list',
                    name: 'marketing.breadcrumb.MARKETINGACTION'
                },
                paymentsList: {
                    type: 'state',
                    state: 'app.payments.list',
                    name: 'payment.card.breadcrumb.PAYMENTCARDS'
                },
                suppliersList: {
                    type: 'state',
                    state: 'app.suppliers.list',
                    name: 'suppliers.breadcrumb.SUPPLIERS'
                },
                salesList: {
                    type: 'state',
                    state: 'app.sales',
                    name: 'sale.breadcrumb.SALE'
                },
                salesReclamationsList: {
                    type: 'state',
                    state: 'app.sale.reclamations',
                    name: 'sale.breadcrumb.RECLAMATIONS'
                },
                salesOffstockList: {
                    type: 'state',
                    state: 'app.sales-offstock',
                    name: 'sale.offstock.breadcrumb.SALEOFFSTOCK'
                },
                testsList: {
                    type: 'state',
                    state: 'app.tests',
                    name: 'test.breadcrumb.TESTS'
                },
                testsResponsibilitiesList: {
                    type: 'state',
                    state: 'app.test.responsibilities.table',
                    name: 'test.breadcrumb.RESPONSIBILITIES'
                },
                testResultsList: {
                    type: 'state',
                    state: 'app.test.results.table',
                    name: 'test.breadcrumb.RESULTS'
                },
                inventoryList: {
                    type: 'state',
                    state: 'app.warehouse.inventories.table',
                    name: 'inventory.breadcrumb.INVENTURIES'
                },
                inventoryQuickList: {
                    type: 'state',
                    state: 'app.warehouse.inventories.quick.table',
                    name: 'inventory.breadcrumb.QUICKS'
                },
                grinderyScoreTypeList: {
                    type: 'state',
                    state: 'app.tasks-grindery.list',
                    name: 'tasks.grindery.breadcrumb.SCORE'
                },
            },
            functions: {
                messages(args) {
                    let result = {
                        name: 'timeline.breadcrumb.MESSAGES'
                    }

                    if(args[0]) {
                        result.type = 'state'
                        result.state = 'app.timeline.view'
                    }

                    return result
                },
                message(args) {
                    let result = {
                        name: 'timeline.breadcrumb.MESSAGE',
                        id: args[0]
                    }

                    if(args[1] === 'state') {
                        result.type = 'state'
                        result.state = "app.timeline.details({id:" + args[0] + "})"
                    }

                    return result
                },
                messageCreate(args) {
                    let result = {
                        name: 'timeline.breadcrumb.CREATE'
                    }

                    if(args[0]) {
                        result.click = args[0]
                    }

                    return result
                },
                patient(args) {
                    let result = {
                        name: 'patient.breadcrumb.PATIENT',
                        id: args[0]
                    }

                    if(args[1] === 'state') {
                        result.type = 'state'
                        result.state = "app.patient.detail({id:" + args[0] + ",'#':'detail'})"
                    }

                    return result
                },
                patientName(args) {
                    let result = {
                        name: 'patient.breadcrumb.PATIENT',
                        id: args[2]
                    }

                    if(args[0] === 'state') {
                        result['type'] = 'state'
                        result['state'] = "app.patient.detail({id:" + args[2] + ",'#':'detail'})"
                        result['id'] += ' ' + args[1]
                    }

                    return result
                },
                patientPrescriptions(args) {
                    let result = {
                        type: 'state'
                    }

                    if(args[1]) {
                        result.state = "app.patient.detail({id:" + args[0] + ",'#':'prescriptions',prescriptionId:" + args[1] + "})"
                        result.name = 'patient.breadcrumb.PRESCRIPTION'
                        result.id = args[1]
                    } else {
                        result.state = "app.patient.detail({id:" + args[0] + ",'#':'prescriptions'})"
                        result.name = 'patient.card.PRESCRIPTIONS'
                    }

                    return result
                },
                patientLoyality(args) {
                    return {
                        type: 'state',
                        state: "app.patient.detail({id:" + args[0] + ",'#':'loyality',cardId:" + args[1] + "})",
                        name: 'patient.breadcrumb.LOYALITYCARD',
                        id: args[1]
                    }
                },
                contactPlusList(args) {
                    let result = {
                        name: 'patient.breadcrumb.CONTACTPLUS'
                    }

                    if(args[0]) {
                        result.type = 'state'
                        result.state = 'app.patients.contact-plus'
                    }

                    return result
                },
                offer(args) {
                    let result = {
                        name: 'tasks.breadcrumb.OFFER',
                        id: args[1]
                    }

                    if(args[2]) {
                        result.type = 'state'
                        result.state = "app.patient.detail({id:" + args[0] + ",'#':'offers',offerId:" + args[1] + "})"
                    }

                    return result
                },
                catalog(args) {
                    let result = {
                        name: 'catalog.breadcrumb.CATALOG'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.catalog.category({id: "-"})'
                    }

                    return result
                },
                hoyaPrices(args) {
                    let result = {
                        name: 'sidebar.catalog.PRICE_HOYA'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.catalog.hoya'
                    }

                    return result
                },
                product(args) {
                    let result = {
                        id: args[0],
                        name: 'catalog.breadcrumb.PRODUCT'
                    }

                    if(args[1] === 'state') {
                        result.type = 'state'
                        result.state = 'app.catalog.product({id: ' + args[0] + '})'
                    }

                    return result
                },
                packs(args) {
                    let result = {
                        name: 'pack.breadcrumb.PACKS'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.packs.list'
                    }

                    return result
                },
                layoutList(args) {
                    let result = {
                        name: 'layout.breadcrumb.LAYOUTS'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.layout.list'
                    }

                    if (args[0] === 'all') {
                        result.name = 'layout.breadcrumb.LAYOUTS_ALL'
                        result.type = 'state'
                        result.state = 'app.layout.all'
                    }

                    return result
                },
                layoutDetails(args) {
                    let result = {
                        name: 'layout.breadcrumb.LAYOUT'
                    }

                    if(args[1]) {
                        result.id = args[1]
                    }

                    if(args[2] === 'state') {
                        result.type = 'state'
                        result.state = 'app.layout.details({id: ' + args[0] + '})'
                    }

                    return result
                },
                layoutRanges(args) {
                    let result = {
                        name: 'layout.breadcrumb.RANGES'
                    }

                    if (args[0]) {
                        result.type = 'state'
                        result.state = 'app.layout.ranges'
                    }

                    return result
                },
                stockCorrectionList(args) {
                    let result = {
                        name: 'catalog.stock.breadcrumb.LIST'
                    }

                    if (args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.catalog.stock-corrections.table'
                    }

                    return result
                },

                patientVisit(args) {
                    let result = {
                        name: 'optometrist.breadcrumb.WAITINGPATIENT'
                    }

                    if (args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.optometrists.list'
                    }

                    return result
                },
                interview(args) {
                    let result = {
                        name: 'optometrist.breadcrumb.INTERVIEW',
                        type: args[0],
                        id: args[1]
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.optometrist.details({id: ' + args[1] + '})'
                    }

                    if(args[2] > 0) {
                        result.id += ' z #' + args[2]
                    }

                    return result
                },

                user(args) {
                    return {
                        name: 'user.breadcrumb.USER',
                        id: args[0]
                    }
                },

                usersTrainingsSchedule(args) {
                    let result = {
                        name: 'user.training.breadcrumb.TRAININGS'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.users.trainings.schedule'
                    }

                    return result
                },
                usersTrainingsDetails(args) {
                    let result = {
                        name: 'user.training.breadcrumb.TRAINING',
                        id: args[1]
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.user.training.details({id: ' + args[1] + '})'
                    }

                    return result
                },

                usersExchangesList(args) {
                    let result = {
                        name: 'user.exchange.breadcrumb.EXCHANGES'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.users.exchanges.list'
                    }

                    return result
                },
                usersExchangesDetails(args) {
                    let result = {
                        name: 'user.exchange.breadcrumb.EXCHANGE',
                        id: args[1]
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.user.exchange.details({id: ' + args[1] + '})'
                    }

                    return result
                },

                role(args) {
                    return {
                        name: 'role.breadcrumb.ROLE',
                        id: args[0]
                    }
                },

                roleExceptions(args) {
                    let result = {
                        name: 'role.breadcrumb.EXCEPTIONS'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.roles.exceptions'
                    }

                    return result
                },

                usersPermissionExtrasList(args) {
                    let result = {
                        name: 'user.permission-extra.breadcrumb.PERMISSIONS'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.users.permission-extras.list'
                    }

                    return result
                },

                departments(args) {
                    let result = {
                        name: 'departments.breadcrumb.DEPARTMENTS'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.departments.list'
                    }

                    return result
                },
                departmentsGroupsList(args) {
                    let result = {
                        name: 'departments.breadcrumb.GROUPS'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.departments.groups.list'
                    }

                    return result
                },
                departmentsOfficesList(args) {
                    let result = {
                        name: 'departments.office.breadcrumb.OFFICES'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.departments.offices.table'
                    }

                    return result
                },
                departmentsOfficesDetails(args) {
                    let result = {
                        name: 'departments.office.breadcrumb.OFFICE',
                        id: args[1]
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.departments.office.details({id: ' + args[1] + '})'
                    }

                    return result
                },
                registerReturnSaleList(args) {
                    let result = {
                        name: 'register.breadcrumb.SALE'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.register.return.sale.table'
                    }

                    return result
                },
                tasks(args) {
                    return functionList('app.tasks', args)
                },
                tasksTemporary() {
                    return {
                        type: 'state',
                        name: 'tasks.temporary.breadcrumb.TEMPORARY',
                        state: 'app.tasks-temporary'
                    }
                },
                task(args) {
                    return {
                        name: 'tasks.breadcrumb.TASK',
                        id: args[0]
                    }
                },

                smsTemplateList(args) {
                    let result = {
                        name: 'sms.template.breadcrumb.LIST'
                    }

                    if (args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.sms.template.list'
                    }

                    return result
                },

                smsCampaignList(args) {
                    let result = {
                        name: 'sms.campaign.breadcrumb.CAMPAIGNS'
                    }

                    if (args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.sms.campaign.list'
                    }

                    return result
                },

                returns(args) {
                    return functionList('app.warehouse.returns', args)
                },
                return(args) {
                    return {
                        name: 'returns.breadcrumb.RETURN',
                        id: args[0]
                    }
                },

                orders(args) {
                    return functionList('app.warehouse.orders', args)
                },
                order(args) {
                    return {
                        name: 'orders.breadcrumb.ORDER',
                        id: args[0]
                    }
                },

                wz(args) {
                    let _return = {
                        name: 'orders.wz.breadcrumb.WZ'
                    }

                    if(args[0] === 'state') {
                        _return.type = 'state'
                        _return.state = 'app.warehouse.order.wz.list'
                    }

                    return _return
                },
                wzId(args) {
                    let _return = {
                        name: 'orders.wz.breadcrumb.ID',
                        id: args[0]
                    }

                    if(args[1] === 'state') {
                        _return.type = 'state'
                        _return.state = 'app.warehouse.order.wz.details({id: ' + args[0] + '})'
                    }

                    return _return
                },

                taskwz(args) {
                    let _return = {
                        name: 'tasks.wz.breadcrumb.TASKSWZ'
                    }

                    if(args[0] === 'state') {
                        _return.type = 'state'
                        _return.state = 'app.tasks.wz.list'
                    }

                    return _return
                },

                printouts(args) {
                    let _return = {
                        name: 'tasks.printouts.breadcrumb.PRINTOUTS'
                    }

                    if(args[0] === 'state') {
                        _return.type = 'state'
                        _return.state = 'app.tasks.printouts.list'
                    }

                    return _return
                },

                outcomingtransfers(args) {
                    return functionList('app.warehouse.transfers.outcoming', args)
                },
                incomingtransfers(args) {
                    return functionList('app.warehouse.transfers.incoming', args)
                },
                transfer(args) {
                    let result = {
                        name: 'transfers.breadcrumb.TRANSFER',
                        id: args[0]
                    }

                    return result
                },

                outcomingshipments(args) {
                    return functionList('app.warehouse.shipments.outcoming', args)
                },
                incomingshipments(args) {
                    return functionList('app.warehouse.shipments.incoming', args)
                },
                shipment(args) {
                    let _return = {
                        name: 'shipments.breadcrumb.SHIPMENT',
                        id: args[0]
                    }

                    if(args[1] === 'state') {
                        _return.type = 'state'
                        _return.state = 'app.warehouse.shipment.detail({id: ' + args[0] + '})'
                    }

                    return _return
                },

                smileList(args) {
                    let result = {
                        name: 'smile.breadcrumb.SMILE'
                    }

                    if (args[0]) {
                        result.type = 'state'
                        result.state = 'app.warehouse.smile.table'
                    }

                    return result
                },

                supplyList(args) {
                    let _return = {
                        name: 'supply.breadcrumb.SUPPLY'
                    }

                    if(args === 'state') {
                        _return.type = 'state'
                        _return.state = 'app.warehouse.supply.list'
                    }

                    return _return
                },
                orderByAdminList(args) {
                    let _return = {
                        name: 'supply.breadcrumb.ORDERBYADMIN'
                    }

                    if(args === 'state') {
                        _return.type = 'state'
                        _return.state = 'app.warehouse.supply.list'
                    }

                    return _return
                },

                salereclamations(args) {
                    return functionList('app.sale.reclamations', args)
                },

                reclamations(args) {
                    return functionList('app.tasks.reclamations', args)
                },
                reclamation(args) {
                    return {
                        name: 'reclamations.breadcrumb.RECLAMATION',
                        id: args[0]
                    }
                },

                register(args) {
                    let result = {
                        name: args[1]
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.register.' + args[1].substr(args[1].lastIndexOf('.') + 1).toLowerCase()
                    }

                    return result
                },

                specificationRegeneration(args) {
                    let result = {
                        name: 'sidebar.register.specifications.REGENERATION'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.specifications.regeneration'
                    }

                    return result
                },

                specificationSale(args) {
                    let result = {
                        name: 'sidebar.register.specifications.SALES'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.specifications.sales'
                    }

                    return result
                },

                specificationTask(args) {
                    let result = {
                        name: 'sidebar.register.specifications.TASKS'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.specifications.tasks'
                    }

                    return result
                },

                test(args) {
                    let result = {
                        name: 'test.breadcrumb.TEST',
                        id: args[1]
                    }

                    if(args[0] === 'state') {
                        result.type = 'state';
                        result.state = 'app.test.details({id: ' + args[1] + '})'
                    }

                    return result
                },
                testResponsibility(args) {
                    let result = {
                        name: 'test.breadcrumb.RESPONSIBILITY',
                        id: args[1]
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.test.responsibility.details({id: ' + args[1] + '})'
                    }

                    return result
                },
                testQuestion(args) {
                    return {
                        name: 'test.breadcrumb.QUESTION',
                        id: args[0]
                    }
                },

                errors(args) {
                    return functionList('app.errors', args)
                },
                error(args) {
                    return {
                        name: 'bugtrack.breadcrumb.ERROR',
                        id: args[0]
                    }
                },

                eShopCustomers(args) {
                    let result = {
                        name: 'eShop.customer.breadcrumb.CUSTOMERS'
                    }

                    if(args[0]) {
                        result.type = 'state'
                        result.state = 'app.eShop.customers'
                    }

                    return result
                },
                eShopOrders(args) {
                    return functionList('app.eShop.orders', args)
                },

                inventoryQuick(args) {
                    return {
                        name: 'inventory.breadcrumb.INVENTORY',
                        id: args[0]
                    }
                },

                roles(args) {
                    let result = {
                        name: 'role.breadcrumb.ROLES'
                    }

                    if(args[0]) {
                        result.type = 'state'
                        result.state = 'app.roles.list'
                    }

                    return result
                },

                taskextraList(args) {
                    return {
                        name: 'dictionary.taskextra.breadcrumb.EXTRAEDIT',
                        id: args[0]
                    }
                },

                reclamationService(args) {
                    return {
                        name: 'dictionary.reclamationservice.breadcrumb.RECLAMATIONSERVICE',
                        id: args[0]
                    }
                },

                regenerationTypeList(args) {
                    return {
                        name: 'dictionary.regenerationtype.breadcrumb.NO',
                        id: args[0]
                    }
                },

                specialistList(args) {
                    return {
                        name: 'dictionary.specialist.breadcrumb.NO',
                        id: args[0]
                    }
                },

                pictogramsIconsList(args) {
                    let result = {
                        name: 'sidebar.settings.PICTOGRAMS'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.dictionary.pictograms.icons.list'
                    }

                    return result
                },

                seasonList(args) {
                    let result = {
                        name: 'dictionary.seasons.breadcrumb.SEASONS'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.dictionary.seasons.table'
                    }

                    return result
                },

                previousLensesContactLensesList(args) {
                    let result = {
                        name: 'sidebar.settings.PREVIOUS_LENSES_CONTACT_LENSES'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.dictionary.previous-lenses-contact-lenses.table'
                    }

                    return result
                },

                trainingTypesList(args) {
                    let result = {
                        name: 'dictionary.trainingtype.breadcrumb.TYPES'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.dictionary.training-types.table'
                    }

                    return result
                },

                ticketsList(args) {
                    let result = {
                        name: 'ticket.breadcrumb.TICKETS'
                    }

                    if (args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.warehouse.tickets.table'
                    }

                    return result
                },

                pricesList(args) {
                    let result = {
                        name: 'prices.breadcrumb.PRICESTOCHANGE'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.prices.list'
                    }

                    return result
                },
                pricesDetails(args) {
                    let result = {
                        name: 'prices.breadcrumb.DETAILS',
                        id: args[0]
                    }

                    if(args[1] === 'state') {
                        result.type = 'state'
                        result.state = 'app.prices.edit({id: ' + args[0] + '})'
                    }

                    return result
                },

                downloadList(args) {
                    let result = {
                        name: 'download.breadcrumb.DOWNLOAD'
                    }

                    if (args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.downloads.table'
                    }

                    return result
                },

                regenerations(args) {
                    return functionList('app.regenerations', args)
                },
                regeneration(args) {
                    let result = {
                        name: 'regeneration.breadcrumb.REGENERATION',
                        id: args[1]
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.regeneration.details({id: ' + args[1] + '})'
                    }

                    return result
                },

                couponsList(args) {
                    let result = {
                        name: 'coupons.breadcrumb.COUPONS'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.coupons.table'
                    }

                    return result
                },
                coupon(args) {
                    let _return = {
                        name: 'coupons.breadcrumb.COUPONS',
                        id: args[0]
                    }

                    if(args[1] === 'state') {
                        _return.type = 'state'
                        _return.state = 'app.coupon.details({id: ' + args[0] + '})'
                    }

                    return _return
                },

                bookkeepingExpensesList(args) {
                    let result = {
                        name: 'bookkeeping.breadcrumb.EXPENSES'
                    }

                    if(args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.bookkeeping.expenses'
                    }

                    return result
                },

                entriesList(args) {
                    let result = {
                        name: 'entries.breadcrumb.LIST'
                    }

                    if (args[0] === 'state') {
                        result.type = 'state'
                        result.state = 'app.entries.list'
                    }

                    return result
                },
            },
            data: {},
            changeLast(elem) {
                this.data[this.data.length - 1] = elem
            },
            title() {
                let result = [],
                    int = angular.isDefined(this.data[0].last) ? this.data[0].last : 1

                for(let i = this.data.length - int, j = this.data.length; i < j; ++i) {
                    result.push(this.data[i])
                }

                return result
            }
        }
    }]
})